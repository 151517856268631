/*======================
    404 page
=======================*/

.not-found-container {
    width: 100vw;
    height: 100vh;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;


    .four_zero_four_bg {
        background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
        min-height: 400px;
        width: 100%;
        background-position: center;
        background-size: cover;

        h1 {
            text-align: center;
        }
    }

    .four_zero_four_bg h1 {
        font-size: 80px;
    }

    .four_zero_four_bg h3 {
        font-size: 80px;
    }

    .link_404 {
        color: #fff !important;
        padding: 10px 20px;
        background: #39ac31;
        margin: 20px 0;
        display: inline-block;
        text-decoration: none;
    }
    .contant_box_404 {
        margin-top: -50px;
    }

    img {
        width: 100%;
    }
}
