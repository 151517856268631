:root {
    font-family: montserrat;
    cursor: default;
    text-align: center;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    overflow-y: scroll;
    --blue: #0071bc;
    --green: #00ba7c;
    --charcol: #323232;
    --silver: #757575;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom css classes */

section {
    @apply w-full px-8
    md:px-16
    lg:px-24
    xl:px-32
}

@layer components {
    .tab_item {
        color: black;
        text-decoration: none;
        transition: transform 0.2s ease;
        font-weight: bold;
        padding: 0px 4px;
    }
    .hover_tab_item {
        transform: translateY(-2px);
        cursor: pointer;
    }

    .active_tab_item {
        border-bottom: 2px solid var(--green);
        transform: translateY(-3px);
        transition:all 0.2s linear;
        cursor: unset;
    }

    .medium_icon {
        height: 24px;
        width: 24px;
        margin: 8px;
        border-bottom: 2px solid transparent;
        transition: background-color 0.3s ease, transform 0.3s ease;
    }

    .medium_icon:hover {
        transform: translateY(-2px);
        border-bottom: 2px solid var(--green);
        cursor: pointer;
    }
    .border_b_transparent {
        border-bottom: 2px solid transparent;
    }
    .hover_border {
        transform: translateY(-2px);
        padding-bottom: 2px;
        scale: 1.15;
        border-bottom: 2px solid var(--green);
        cursor: pointer;
    }
}

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    .prose :where(iframe):not(:where([class~="not-prose"] *)) {
		@apply aspect-video w-full h-auto
	}
}

/* .grecaptcha-badge { 
    visibility: hidden !important;
} */
